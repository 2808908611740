import { ProtectedApi } from "../Helpers/api";

export const SearchMission = async (search, location) => {
  return ProtectedApi.get(
    `/mission/search${search ? `?search=${search}` : ""}${location ? `${search ? "&" : "?"}address=${location}` : ""
    }`
  );
};

export const GetMissions = async ({
  page,
  duration,
  skills,
  workRythmn,
  workLocation,
  consultantId,
}) => {
  return ProtectedApi.get("/mission/dashboard/consultant", {
    params: {
      p: page,
      duration,
      skills,
      workRythmn,
      workLocation,
      consultantId,
    },
  });
};
export const GetMissionsFilters = async (consultantId) => {
  return ProtectedApi.get("/mission/dashboard/consultant/filters", { params: { consultantId } });
};
export const GetConsultantMissions = (id) => {
  return ProtectedApi.get(`/mission/consultant/${id}`);
};

export const GetConsultantEndOfMonth = (consultantId) => {
  return ProtectedApi.get("/end-of-month", { params: { consultantId } });
};
export const GetMission = async (id, consultantId) => {
  return ProtectedApi.get(`/mission/dashboard/consultant/details/${id}?consultantId=${consultantId}`);
};

export const PostulerMission = (payload) => {
  return ProtectedApi.post("/application", payload);
};

export const LikeOrDislikeMission = (payload) => {
  return ProtectedApi.post(`/consultant/favorite`, payload);
};

export const GetMissionFavorites = (id) => {
  return ProtectedApi.get(`/favorite/${id}`);
};
export const GetMissionSuggestion = (consultantId) => {
  return ProtectedApi.get(
    `/mission/consultant/${consultantId}/suggestedMission`
  );
};
