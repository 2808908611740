import React, { useState } from "react";
import { FiSearch, FiXCircle, FiSliders, FiArrowLeft } from "react-icons/fi";
import SearchBar from "../../Components/SearchBar";
import style from "./style.module.css";
import CustomCheckbox from "./../../Components/CustomCheckbox/index";
import Mission from "../../Components/Mission";
import Pagination from "../../Components/Pagination";
import { useEffect } from "react";
import { GetMissions, SearchMission } from "../../Services/Missions";
import Loader from "./../../Components/Loader/index";
import { GetMissionsFilters } from "./../../Services/Missions";
import { durations as Durations } from "../../Helpers/data";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const queryString = require("query-string");

const Missions = () => {
  const location = useLocation();
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [page, setPage] = useState(1);
  const [nbPages, setNbPages] = useState(1);

  const [loading, setLoading] = useState(false);

  const [durations, setDurations] = useState([]);
  const [durationsFilters, setDurationsFilters] = useState([]);
  const [durationsFiltersMobile, setDurationsFiltersMobile] = useState([]);

  const [skills, setSkills] = useState([]);
  const [skillsFilters, setSkillsFilters] = useState([]);
  const [skillsFiltersMobile, setSkillsFiltersMobile] = useState([]);

  const [workRythmn, setWorkRythmns] = useState([]);
  const [workRythmsFilters, setWorkRythmsFilters] = useState([]);
  const [workRythmsFiltersMobile, setWorkRythmsFiltersMobile] = useState([]);

  const [workLocations, setWorkLocations] = useState([]);
  const [workLocationsFilters, setWorkLocationsFilters] = useState([]);
  const [workLocationsFiltersMobile, setWorkLocationsFiltersMobile] = useState(
    {}
  );

  const [openSearch, setOpenSearch] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const [missions, setMissions] = useState([]);

  useEffect(() => {
    setDurationsFiltersMobile([...durationsFilters]);
  }, [durationsFilters]);

  useEffect(() => {
    setSkillsFiltersMobile([...skillsFilters]);
  }, [skillsFilters]);

  useEffect(() => {
    setWorkRythmsFiltersMobile([...workRythmsFilters]);
  }, [workRythmsFilters]);

  useEffect(() => {
    setWorkLocationsFiltersMobile([...workLocationsFilters]);
  }, [workLocationsFilters]);

  useEffect(() => {
    if (currentConsultant) {
      GetMissionsFilters(currentConsultant ? currentConsultant.id : '').then((res) => {
        setSkills(res.data.skills);
        setDurations(res.data.duration);
        setWorkRythmns(res.data.workRythmn);
        setWorkLocations(res.data.workLocation);
      });
    }
  }, [currentConsultant]);

  useEffect(() => {
    setLoading(true);
    if (currentConsultant) {
      GetMissions({
        page: page - 1,
        duration: [...durationsFilters],
        skills: [...skillsFilters],
        workRythmn: [...workRythmsFilters],
        workLocation: [...workLocationsFilters],
        consultantId: currentConsultant.id,
      })
        .then((res) => {
          const sortedMissions = res.data.missions.sort((m1, m2) =>
            m1.label.localeCompare(m2.label)
          );
          setMissions(sortedMissions);
          setNbPages(res.data.numberOfPages);
          setLoading(false);
        })
        .catch((e) => setLoading(false));
    }
  }, [
    currentConsultant,
    page,
    durationsFilters,
    skillsFilters,
    workRythmsFilters,
    workLocationsFilters,
  ]);

  useEffect(() => {
    if (location && (location.search || location.address)) {
      const params = queryString.parse(location.search);
      SearchMission(params.search, params.address).then((res) =>
        setMissions(res.data)
      );
    }
  }, [location]);

  const handleChangeLike = (missionUpdated) => {
    const updatedMissions = [
      ...missions.filter((mission) => mission.id !== missionUpdated.id),
      missionUpdated,
    ];
    const sortedMissions = updatedMissions.sort((m1, m2) =>
      m1.label.localeCompare(m2.label)
    );

    setMissions(sortedMissions);
  };
  return (
    <div className={"container-lg " + style["missions-page"]}>
      <div className="row justify-content-center mb-3 mb-md-5">
        <div className="col-12 col-md-10 mx-1 d-flex flex-row justify-content-between align-items-center">
          <div className="header-4 font-family-tt">Trouvez votre mission idéale</div>
          <div>
            <button
              type="button"
              className="default-btn d-flex d-md-none p-3"
              onClick={(e) => setOpenSearch(true)}
            >
              <FiSearch />
            </button>
          </div>
        </div>
      </div>
      <SearchBar search={true} />
      <div
        className={
          "card d-flex flex-row justify-content-center  d-md-none " +
          style["filter-btn"]
        }
      >
        <button
          className="w-50 default-btn "
          onClick={(e) => setOpenFilters(true)}
        >
          Filtre
          <FiSliders className="mx-2" />
        </button>
      </div>
      <div className="row justify-content-center mx-1 mt-3 mt-md-5 mb-4">
        <div className="col-12 col-md-10 d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center mt-0 mt-md-5">
          <div className="d-flex flex-row align-items-baseline w-100">
            <div className="small-text black-text mx-2">
              5 résultats par page
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-1 gap-4">
        <div className="col-4 d-none card p-5 d-md-flex flex-column gap-5">
          <div className="d-flex flex-column gap-4">
            <div className="subtitle bold">Durée</div>
            {durations.map((d, i) => {
              return (
                <CustomCheckbox
                  key={i}
                  label={d[1]}
                  name={d[0]}
                  value={durationsFilters.includes(d[0])}
                  onChange={(e) => {
                    setPage(1);
                    setDurationsFilters(
                      e.target.checked
                        ? [...durationsFilters, d[0]]
                        : durationsFilters.filter((f) => f !== d[0])
                    );
                  }}
                />
              );
            })}
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="subtitle bold">Type de contrat</div>
            {workRythmn.map((wr, i) => {
              return (
                <CustomCheckbox
                  key={i}
                  label={wr[1]}
                  name={wr[0]}
                  value={workRythmsFilters.includes(wr[0])}
                  onChange={(e) => {
                    setPage(1);
                    setWorkRythmsFilters(
                      e.target.checked
                        ? [...workRythmsFilters, wr[0]]
                        : workRythmsFilters.filter((id) => id !== wr[0])
                    );
                  }}
                />
              );
            })}
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="subtitle bold">Sur site / A distance</div>
            {workLocations.map((mt, i) => {
              return (
                <CustomCheckbox
                  key={i}
                  label={mt[1]}
                  name={mt[0]}
                  value={workLocationsFilters.includes(mt[1])}
                  onChange={(e) => {
                    setPage(1);
                    setWorkLocationsFilters(
                      e.target.checked
                        ? [...workLocationsFilters, mt[1]]
                        : workLocationsFilters.filter((f) => f !== mt[1])
                    );
                  }}
                />
              );
            })}
          </div>
          {/* <div className="d-flex flex-column gap-4">
            {skills.length > 0 && (
              <div className="subtitle bold">Compétences</div>
            )}
            {skills.map((s, i) => {
              return (
                <CustomCheckbox
                  key={i}
                  label={s.label}
                  name={s.label}
                  value={skillsFilters.includes(s.id)}
                  onChange={(e) => {
                    setPage(1);
                    setSkillsFilters(
                      e.target.checked
                        ? [...skillsFilters, s.id]
                        : skillsFilters.filter((id) => id !== s.id)
                    );
                  }}
                />
              );
            })}
          </div> */}
        </div>
        <div className="col d-flex flex-column gap-3 align-items-center">
          {loading ? (
            <div
              className="w-100 position-relative card"
              style={{ height: "300px" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {missions.length > 0 ? (
                <>
                  {missions.map((mission, i) => {
                    return (
                      <Mission
                        mission={mission}
                        key={i}
                        onChangeLike={handleChangeLike}
                      />
                    );
                  })}
                  <div className="mt-5">
                    <Pagination
                      currentPage={page}
                      nbPages={nbPages}
                      setPage={setPage}
                    />
                  </div>
                </>
              ) : (
                <div className="w-100 card p-5 wow-body text-center">
                  Aucune mission
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Mobile version of search bar     */}
      <div
        className={`${style["search-mobile"]} ${openSearch ? style["open"] : ""
          } container-fluid d-flex flex-column d-md-none`}
      >
        <FiXCircle
          className={style["search-mobile-close"]}
          onClick={(e) => setOpenSearch(false)}
        />
        <SearchBar onSearch={() => setOpenSearch(false)} />
      </div>

      {/* Mobile version of filters */}
      <div
        className={`${style["filters-mobile"]} ${openFilters ? style["open"] : ""
          } container-fluid d-flex flex-column d-md-none`}
      >
        <FiArrowLeft
          className={style["filters-mobile-return"]}
          onClick={(e) => {
            setOpenFilters(false);
            setDurationsFiltersMobile([...durationsFilters]);
            setSkillsFiltersMobile([...skillsFilters]);
            setWorkRythmsFiltersMobile([...workRythmsFilters]);
            setWorkLocationsFiltersMobile([...workLocationsFilters]);
          }}
        />
        <div className="row justify-content-center">
          <div className="col-10 d-flex flex-column gap-5">
            <div className="d-flex flex-column gap-4">
              <div className="subtitle bold">Durée</div>
              {Object.keys(durations).map((s, i) => {
                return (
                  <CustomCheckbox
                    key={i}
                    label={Durations[s]}
                    name={s + "mobile"}
                    value={durationsFiltersMobile.includes(s)}
                    onChange={(e) => {
                      setPage(1);
                      setDurationsFiltersMobile(
                        e.target.checked
                          ? [...durationsFiltersMobile, s]
                          : durationsFiltersMobile.filter((f) => f !== s)
                      );
                    }}
                  />
                );
              })}
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="subtitle bold">Type de contrat</div>
              {workRythmn.map((s, i) => {
                return (
                  <CustomCheckbox
                    key={i}
                    label={s.name}
                    name={s.name + "mobile"}
                    value={workRythmsFiltersMobile.includes(s.id)}
                    onChange={(e) => {
                      setPage(1);
                      setWorkRythmsFiltersMobile(
                        e.target.checked
                          ? [...workRythmsFiltersMobile, s.id]
                          : workRythmsFiltersMobile.filter((id) => id !== s.id)
                      );
                    }}
                  />
                );
              })}
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="subtitle bold">Sur site / A distance</div>
              {Object.keys(workLocations).map((s, i) => {
                return (
                  <CustomCheckbox
                    key={i}
                    label={workLocations[s]}
                    name={s + "mobile"}
                    value={workLocationsFiltersMobile.includes(s)}
                    onChange={(e) => {
                      setPage(1);
                      setWorkLocationsFiltersMobile(
                        e.target.checked
                          ? [...workLocationsFiltersMobile, s]
                          : workLocationsFiltersMobile.filter((f) => f !== s)
                      );
                    }}
                  />
                );
              })}
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="subtitle bold">Compétences</div>
              {skills.map((s, i) => {
                return (
                  <CustomCheckbox
                    key={i}
                    label={s.name}
                    name={s.name + "mobile"}
                    value={skillsFiltersMobile.includes(s.id)}
                    onChange={(e) => {
                      setPage(1);
                      setSkillsFiltersMobile(
                        e.target.checked
                          ? [...skillsFiltersMobile, s.id]
                          : skillsFiltersMobile.filter((id) => id !== s.id)
                      );
                    }}
                  />
                );
              })}
            </div>
            <button
              className="default-btn"
              onClick={(e) => {
                setDurationsFilters([...durationsFiltersMobile]);
                setSkillsFilters([...skillsFiltersMobile]);
                setWorkRythmsFilters([...workRythmsFiltersMobile]);
                setWorkLocationsFilters([...workLocationsFiltersMobile]);
                setOpenFilters(false);
              }}
            >
              Appliquer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Missions;
