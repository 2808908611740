import axios from "axios";
import { RefreshAccessToken } from "../Services/Auth";
import {
  getAccessToken,
  getRefreshToken,
  persistCurrentUser,
  removeCurrentUser,
} from "./jwt";
import { addLoader, removeLoader } from "./files-loader";
import toast from "./toast";
import createAuthRefreshInterceptor from "axios-auth-refresh";

console.log(process.env.NODE_ENV);
const Api = axios.create({
  headers: {
    "content-type": "application/json",
    "access-control-allow-headers": "*",
    "access-control-allow-origin": "*",
  },
  withCredentials: true,
});
Api.defaults.baseURL = process.env.NODE_ENV !== "development" ? "/api" : "";
export const ProtectedApi = axios.create({
  headers: {
    "Content-Type": "application/json",
    "access-control-allow-headers": "*",
    "access-control-allow-origin": "*",
  },
  withCredentials: true,
});
ProtectedApi.defaults.baseURL =
  process.env.NODE_ENV !== "development" ? "/api" : "";
const refreshAuthLogic = () =>
  axios
    .post("/auth/refresh", { id: sessionStorage.getItem("USER_ID") })
    .then((res) => Promise.resolve());

createAuthRefreshInterceptor(ProtectedApi, refreshAuthLogic);

export const ProtectedFilesApi = axios.create({
  headers: {
    "Content-Type": "multipart/form-data",
    "access-control-allow-headers": "*",
  },
});
ProtectedFilesApi.defaults.baseURL =
  process.env.NODE_ENV !== "development" ? "/api" : "";

ProtectedFilesApi.interceptors.request.use(
  async (req) => {
    addLoader();
    const accessToken = await getAccessToken();
    req.headers.common["Authorization"] = "Bearer " + accessToken;
    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);
ProtectedFilesApi.interceptors.response.use(
  (res) => {
    removeLoader();
    return res;
  },
  async (error) => {
    removeLoader();
    if (error.response) {
      const originalConfig = error.config;
      if (error.response.status === 409 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await RefreshAccessToken();
          const refreshToken = await getRefreshToken();
          const accessToken = rs.data.accessToken;
          persistCurrentUser({ refreshToken, accessToken });
          // ProtectedFilesApi.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
          return ProtectedApi(originalConfig);
        } catch (_error) {
          removeCurrentUser();
          window.location.reload();
          return Promise.reject(_error);
        }
      } else toast("error", error.response.data.reason);
    } else toast("error", "Veuillez vérifier votre connexion internet");
    return Promise.reject(error);
  }
);
export default Api;
